import { React, useState, useEffect } from "react";
import logo from "../../Images/logo.png";
import profile from "../../Images/profile-user.png";
import profit from "../../Images/profit.png";
import hand from "../../Images/palm-of-hand.png";
import code from "../../Images/password-code.png";
import bank from "../../Images/bank-statement.png";
import logout from "../../Images/logout.png";

import downarraow from "../../Images/down-arrow.png";

import "./Profile.css";
import Account from "../account/Account";
import Navigation from "../navigation/Navigation";
import { NewTheme } from "../../Theme/Theme";
import Marquee from "./Marquee";

const Profile = () => {
  const [ipAddress, setIPAddress] = useState("");

  const [imageExists, setImageExists] = useState(true);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  const Logout = () => {
    localStorage.clear();
    window.location = "/";
  };

  return (
    <>
      <div
        className="Profile_Container"
        style={{
          position: "relative",
          overflowX: "hidden",
          background: NewTheme.lightmain,
        }}
      >
        <div
          className="back_color_box"
          style={{
            position: "absolute",
            height: 270,
            borderBottomLeftRadius: "100%",
            top: "-15%",
            borderBottomRightRadius: "100%",
            width: "130%",
            left: "-15%",
          }}
        ></div>
        <p className="ip">{ipAddress}</p>

        <div className="top_box">
          <div className="logo_box" style={{ height: 180, width: 180 }}>
            <img
              src={logo}
              alt=""
              className="logo_img"
              style={{ height: 120, width: 120 }}
            />
          </div>
          <div className="text_box" style={{ marginTop: 5 }}>
            {/* <p className="server_text">{localStorage.getItem("server")}</p> */}
            <p className="server_text">
              {JSON.parse(localStorage.getItem("client_details")).username}
            </p>
            <p className="username_text">
              {JSON.parse(localStorage.getItem("client_details")).name}
              {/* {ipAddress} */}
            </p>
          </div>
        </div>
        <div className="lower_box" style={{ marginTop: 0 }}>
          <Marquee />

          <div
            className="list_div"
            onClick={() => {
              window.location = "/account";
            }}
          >
            <img
              style={{
                height: 40,
                width: 40,
              }}
              src={
                "https://img.dreamtrade.biz/" +
                localStorage.getItem("server").toLowerCase() +
                ".gif?id=" +
                new Date()
              }
              alt=""
              className="list_icon"
            />

            <p className="list_text">Account Details</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
          <div className="list_divider" />
          <div
            className="list_div"
            onClick={() => {
              window.location = "/ledger";
            }}
          >
            <img
              style={{ height: 25, width: 25 }}
              src={profit}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Ledger Account</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
          <div className="list_divider" />
          <div
            className="list_div"
            onClick={() => {
              window.location = "/rejection";
            }}
          >
            <img
              style={{ height: 25, width: 25 }}
              src={hand}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Regection Logs</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
          <div className="list_divider" />
          <div
            className="list_div"
            onClick={() => {
              window.location = "/changepass";
            }}
          >
            <img
              style={{ height: 25, width: 25 }}
              src={code}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Change Password</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
          <div className="list_divider" />
          <div
            className="list_div"
            onClick={() => {
              window.location = "/bill";
            }}
          >
            <img
              style={{ height: 25, width: 25 }}
              src={bank}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Bill / Statement</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>

          <div className="list_divider" />
          <div onClick={Logout} className="list_div">
            <img
              style={{ height: 25, width: 25 }}
              src={logout}
              alt=""
              className="list_icon"
            />
            <p className="list_text">Logout</p>
            <img
              style={{ height: 25, width: 25 }}
              src={downarraow}
              alt=""
              className="list_next_icon"
            />
          </div>
        </div>
        <div className="space"></div>
      </div>
      <Navigation navScreen="profile" />
    </>
  );
};

export default Profile;
