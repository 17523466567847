import { React, useState, useEffect, useCallback } from "react";
// import useWindowSize from 'usehooks-ts'

import moment from "moment";
import "./Trades.css";
import logo from "../../logo.svg";
import minus from "../../Images/minus.png";
import plus from "../../Images/plus.png";
import Backend from "../../Backend";
import DateFilter from "../datefilter/DateFilter";
import { NewTheme } from "../../Theme/Theme";
import { useWindowSize } from "@uidotdev/usehooks";
import TradeaCard from "./TradeaCard";
const backend = new Backend();
const url = "http://apis.bbt.market/UserPhp/user_api.php";

const Trades = () => {
  const date = new Date();
  const { height, width } = useWindowSize();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const [transaction, SetTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brokerage, SetBrokerage] = useState("");
  const [profitloss, SetProfitloss] = useState("");
  const [transactionSelected, setTransactionSelected] = useState("");
  const [fromDate, setFromDate] = useState(`${year}-${month}-${day}`);
  const [toDate, setToDate] = useState(`${year}-${month}-${day}`);
  const [filterStatus, setFilterStatus] = useState(false);
  const [dateArange, setDateArange] = useState("");
  useEffect(() => {
    // loadTransaction();

    loadTransactionfilter();
    // todayFilter();
  }, []);

  const todayFilter = () => {
    // loadTransactionfilter(
    //   moment().format("DD-MM-YYY 00:00:00"),
    //   moment().format("DD-MM-YYY 23:59:59")
    // );
    // let datetoday = new Date();
    // let daytoday = datetoday.getDate();
    // let monthtoday = datetoday.getMonth() + 1;
    // let yeartoday = datetoday.getFullYear();
    // setToDate(`${yeartoday}-${monthtoday}-${daytoday}`);
    // setFromDate(`${yeartoday}-${monthtoday}-${daytoday}`);
    // console.log(fromDate);
    // console.log(toDate);
    // console.log("fromDate");
    // loadTransactionfilter(
    //   `${yeartoday}-${monthtoday}-${daytoday}`,
    //   `${yeartoday}-${monthtoday}-${daytoday}`
    // );
    // setFilterStatus(false);
  };
  const weekFilter = () => {
    let datetoday = new Date();
    let daytoday = datetoday.getDate();
    let monthtoday = datetoday.getMonth() + 1;
    let yeartoday = datetoday.getFullYear();
    setToDate(`${yeartoday}-${monthtoday}-${daytoday}`);

    var dateweek = new Date();

    //Change it so that it is 7 days in the past.
    var pastDate = dateweek.getDate() - 7;
    dateweek.setDate(pastDate);

    //Log the date to our web console.
    console.log(dateweek.getDate());
    console.log(dateweek.getMonth() + 1);
    console.log(dateweek.getFullYear());

    // let dateweek = new Date();
    let dayweek = dateweek.getDate();
    let monthweek = dateweek.getMonth() + 1;
    let yearweek = dateweek.getFullYear();
    // setToDate(`${yearweek}-${monthweek}-${dayweek}`)
    setFromDate(`${yearweek}-${monthweek}-${dayweek}`);
    console.log(fromDate);
    console.log(toDate);
    console.log("fromDate");
    loadTransactionfilter(
      `${yearweek}-${monthweek}-${dayweek}`,
      `${yeartoday}-${monthtoday}-${daytoday}`
    );
    setFilterStatus(false);
  };

  const loadTransactionfilter = (froma, toa) => {
    var from = moment().format("DD-MM-YYYY 00:00:00");
    var to = moment().format("DD-MM-YYYY 23:59:59");
    // console.log("date format");
    // console.log(toDate);

    // console.log(typeof toDate);
    // console.log("inside the transaction fileter");

    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      from: froma,
      to: toa,
    };
    // alert(JSON.stringify(data));

    backend.loadTransactions(data).then((r) => {
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);

      SetTransactions(r.data);

      // refRBSheet.current.close();

      if (r.error == "False") {
        // console.log(r);

        let totalbrokerage = 0;
        let totalpf = 0;
        r.data.forEach((transaction) => {
          // const date = moment(transaction.date_created).format("DD-MMM-YY");
          totalbrokerage =
            parseFloat(totalbrokerage) + parseFloat(transaction.brokerage_amt);
          totalpf = parseFloat(totalpf) + parseFloat(transaction.profit_loss);
          // if (finalObj[date]) {
          //   finalObj[date].push(transaction);
          // } else {
          //   finalObj[date] = [transaction];
          // }
        });

        // SetTransactions(finalObj);
        // SetTransactions(r.data);
        // console.log(transaction);
        SetProfitloss(totalpf);
        SetBrokerage(totalbrokerage);
        // console.log(brokerage)
        // console.log(profitloss)
        // console.log("inside here to display")
      } else {
        localStorage.clear();
        window.location = "/";
      }
      // console.log(r);
    });
  };
  console.log("position[0]");

  const handleKeyPress = useCallback((event) => {
    console.log(`key pressed: ${event.key}`);
    if (event.key === "ArrowUp") {
      console.log(transaction);
      console.log("position");
      setTransactionSelected(
        transaction[transaction.indexOf(transactionSelected) - 1]
      );
    }
    if (event.key === "ArrowDown") {
      console.log(transaction);
      console.log("position");
      setTransactionSelected(
        transaction[transaction.indexOf(transactionSelected) + 1]
      );
    }
  });
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  const onclickevent = useCallback((item) => {
    setTransactionSelected(item);
  });

  console.log();

  return (
    <>
      <div
        style={{
          background: "white",
          width: "90%",
          height: 150,
          marginLeft: "5%",
          marginTop: 20,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: NewTheme.MainColor,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            borderStyle: "solid",
            borderWidth: 0,
            borderBottomWidth: 1,
          }}
        >
          <div
            style={{
              flex: 1,
              borderStyle: "solid",
              borderWidth: 0,
              borderRightWidth: 1,
              height: "80%",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 12 }}>Commsion</div>
            <div style={{ color: NewTheme.redcolor, fontWeight: "bold" }}>
              {parseFloat(brokerage).toFixed(2)}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                height: 40,
                width: 100,
                borderRadius: 0,
                color: NewTheme.MainColor,
                fontWeight: "bold",
              }}
              onClick={() => setFilterStatus(true)}
            >
              FILTER
            </button>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {localStorage.getItem("server").toUpperCase() == "TEST" ? (
            <div style={{ fontSize: 12, fontWeight: "bold", color: "red" }}>
              DEMO ACCOUNT
            </div>
          ) : null}
          <div style={{ fontWeight: "bold", fontSize: 12 }}>Booked P/L</div>
          <div
            style={{
              color: profitloss < 0 ? NewTheme.redcolor : NewTheme.greencolor,
              fontWeight: "bold",
              fontSize: 25,
            }}
          >
            {parseFloat(profitloss).toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          background: NewTheme.MainColor,
          height: 120,
          width: "100%",
          top: 0,
          zIndex: -1,
        }}
      ></div>
      <div
        style={{
          width: "100%",
          borderStyle: "solid",
          borderWidth: 0,
          borderBottomWidth: 1,
          marginTop: 5,
        }}
      ></div>
      <div
        style={{
          width: "100%",
          height: height - 240,
          overflowY: "scroll",
          // marginTop: 20,
        }}
      >
        {transaction.map((i, t) => (
          <TradeaCard data={i} />
        ))}
        {/* <div style={{ height: 1000, background: "red" }}></div>
         */}
        <div style={{ height: 70, background: "white" }}></div>
      </div>

      {filterStatus ? (
        <div
          className="DateFilter_Container"
          style={{ height: "auto", bottom: 50 }}
        >
          <p className="title_text">Select Date Range</p>
          <p className="input_text" onClick={() => todayFilter()}>
            Today <span>{`${toDate}`}</span>
          </p>
          <p className="input_text" onClick={() => weekFilter()}>
            This Week
            <span>{`${fromDate} to ${toDate}`}</span>
            {/* <input placeholder="10-09-09" type="datetime-local" className="date_input" name="" id="" /> */}
          </p>
          <p className="title_text">Filter By custom Date</p>
          <p className="input_text">
            From Date
            <span>
              <input
                type="date"
                id="start"
                name="trip-start"
                value={fromDate}
                min="2023-01-01"
                max={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  setFromDate(e.target.value);
                  console.log(fromDate);
                }}
              />
            </span>
          </p>
          <p className="input_text">
            To Date
            <span>
              <input
                type="date"
                id="start"
                name="trip-start"
                value={toDate}
                min="2023-01-01"
                max={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
              />
            </span>
          </p>
          <button
            type="submit"
            style={{ color: "black" }}
            onClick={() => {
              loadTransactionfilter(fromDate, toDate);
              // setFromDate("");
              // setToDate("");
              setFilterStatus(false);
            }}
          >
            Submit
          </button>
          <div className="space"></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Trades;
