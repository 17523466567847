import { React, useState, useCallback, useEffect, useRef } from "react";
import "./Watchlist.css";
import logo from "../../logo.svg";
import search_icon from "../../Images/magnifying-glass.png";

import arrow from "../../Images/arrow.png";

import Watchitem from "./Watchitem";

import Backend from "../../Backend";
import StockDetails from "../stockdetail/StockDetails";
import { json } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useWindowSize } from "@uidotdev/usehooks";
import Newsicon from "./Newsicon";
const backend = new Backend();

const Watchlist = () => {
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState([]);

  const [list, setList] = useState([]);
  const { height, width } = useWindowSize();

  const [selected, setSelected] = useState(list[0]);

  const [searchSelected, setSearchSelected] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    loadwatchlistfirst();
  }, []);

  const loadwatchlistfirst = () => {
    setLoading(true);
    let data = {
      sv: localStorage.getItem("server"),
      id: localStorage.getItem("client"),
      jwt: localStorage.getItem("token"),
      filter: localStorage.getItem("watchselect"),
    };

    backend.loadwatchlist(data).then((r) => {
      setTimeout(() => {
        setLoading(false);
        // refRBSheet.current.open();
      }, 500);
      // console.log(r);

      if (r.error == "False") {
        console.log(r.data);

        // props.setData(r.data);
        localStorage.setItem("watchlist", JSON.stringify(r.data));
        setList(r.data);
        console.log("list", list);
      } else {
        localStorage.clear();
        window.location = "/";
      }
      // console.log(r);
    });
  };

  const [selectTab, setSelectTab] = useState(
    localStorage.getItem("watchselect")
  );

  return (
    <>
      <div className="Watchlist_Container">
        <div
          style={{
            height: 50,
            display: "flex",
            paddingRight: 10,
            boxSizing: "border-box",
            alignItems: "center",
            paddingLeft: 5,
          }}
        >
          <div
            onClick={() => (window.location = "/search")}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              height: 40,
              borderRadius: 5,
              borderColor: "gray",
            }}
          >
            <img
              src={search_icon}
              style={{ height: 20, width: 20, marginLeft: 10, marginRight: 10 }}
            />

            <div
              style={{
                height: 35,
                borderWidth: 0,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              Search Script Here
            </div>
          </div>
          {/* <div
            style={{
              width: 40,
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            <img src={arrow} style={{ height: 25, width: 25 }} />
          </div> */}
          <Newsicon />
        </div>

        <div className="menu_bar">
          <div className="inter_menu_bar">
            <div
              className="menu_box"
              style={{
                borderBottomWidth: selectTab == 0 ? 2 : 0,
              }}
              onClick={() => {
                localStorage.setItem("watchselect", 0);
                setSelectTab(0);
                loadwatchlistfirst();

                localStorage.setItem("selectTab", 0);
              }}
            >
              <p className="menu_text">First</p>
            </div>
            <div
              className="menu_box"
              style={{
                borderBottomWidth: selectTab == 1 ? 2 : 0,
              }}
              onClick={() => {
                localStorage.setItem("watchselect", 1);
                setSelectTab(1);
                loadwatchlistfirst();
                localStorage.setItem("selectTab", 1);
              }}
            >
              <p className="menu_text">Second</p>
            </div>
            <div
              className="menu_box"
              style={{
                borderBottomWidth: selectTab == 2 ? 2 : 0,
              }}
              onClick={() => {
                localStorage.setItem("watchselect", 2);
                setSelectTab(2);
                loadwatchlistfirst();
                localStorage.setItem("selectTab", 2);
              }}
            >
              <p className="menu_text">Third</p>
            </div>
          </div>
        </div>
        {/* <p>Somthing to write here</p> */}
        <div
          style={{
            display: "flex",
            fontWeight: "bold",
            padding: 5,
            borderStyle: "solid",
            borderWidth: 0,
            borderBottomWidth: 1,
          }}
        >
          <div style={{ flex: 2 }}>Script</div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            BID
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            ASK
          </div>
        </div>
        <div
          style={{
            height: height - 195,
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {list &&
            list.map((item, index) => {
              return (
                <div
                  className="exterior"
                  onClick={() => {
                    localStorage.setItem("item", JSON.stringify(item));
                    window.location = "/stock";
                  }}
                >
                  <Watchitem key={item.script_id} item={item} />
                </div>
              );
            })}
          <div style={{ height: 80 }}></div>
        </div>
      </div>
    </>
  );
};

export default Watchlist;
